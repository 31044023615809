import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router';
import {AuthGuard, RoleBasedGuard} from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard/layout';
import {Authorities} from "../../utils/authorities";


// ----------------------------------------------------------------------


// Companies
const CompanyListPage = lazy(() => import('../../pages/company/companylist'));
const CompanyNewPage = lazy(() => import('../../pages/company/companycreate'));
const CompanyEditPage = lazy(() => import('../../pages/company/companyedit'));

export const companyRoutes = [

  {
    path: 'companies',
    element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
    ),
    children: [
      {path: 'list', element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.READ_JOURNAL]} hasContent><CompanyListPage/></RoleBasedGuard>},
      {path: 'new', element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.WRITE_JOURNAL]} hasContent><CompanyNewPage/></RoleBasedGuard>},
      {path: ':id/edit', element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.WRITE_JOURNAL]} hasContent><CompanyEditPage/></RoleBasedGuard>},
    ],
  },
]
