import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const accessToken = sessionStorage ? sessionStorage.getItem('accessToken') : '';

let headers = {};
if (accessToken) {
  headers = { Authorization: `Bearer ${accessToken}`};
}

const axiosInstance = axios.create({ baseURL: HOST_API, headers: headers });


axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/users/list',
    login: '/users/authenticate',
    register: '/api/auth/register',
  },
  common: {
    districts: `${HOST_API}districts`,
    accounts: `${HOST_API}accounts`,
    journals: `${HOST_API}journals`,
    dashboard: `${HOST_API}dashboard`,
    companies: `${HOST_API}companies`,
  }
};
