// utils

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  CUSTOMERS: '/customers',
  ACCOUNT: '/accounts',
  JOURNAL: '/journals',
  EXTRACT: '/extracts',
  COMPANY: '/companies',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/app/login`,
    },
  },

  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    balancete: {
      root: `${ROOTS.DASHBOARD}/balancete`,
    },
    permission: {
      root: `${ROOTS.DASHBOARD}/permissions`,
      list: `${ROOTS.DASHBOARD}/permissions/list`,
    },
    costcenter: {
      root: `${ROOTS.DASHBOARD}/costcenters`,
      list: `${ROOTS.DASHBOARD}/costcenters/list`,
    },
    role: {
      root: `${ROOTS.DASHBOARD}/roles`,
      list: `${ROOTS.DASHBOARD}/roles/list`,
      new: `${ROOTS.DASHBOARD}/roles/new`,
      edit: (id: number) => `${ROOTS.DASHBOARD}/roles/${id}/edit`
    },
    user: {
      root: `${ROOTS.DASHBOARD}/users`,
      list: `${ROOTS.DASHBOARD}/users/list`,
      new: `${ROOTS.DASHBOARD}/users/new`,
      edit: (id: number) => `${ROOTS.DASHBOARD}/users/${id}/edit`,
    },
    customer: {
      root: `${ROOTS.CUSTOMERS}`,
      list: `${ROOTS.CUSTOMERS}/list`,
      new: `${ROOTS.CUSTOMERS}/new`,
      edit: (id: number) => `${ROOTS.CUSTOMERS}/${id}/edit`,
    },
    journal: {
      root: `${ROOTS.JOURNAL}`,
      list: `${ROOTS.JOURNAL}/list`,
      new: `${ROOTS.JOURNAL}/new`,
      edit: (id: number) => `${ROOTS.JOURNAL}/${id}/edit`,
    },
    account: {
      root: `${ROOTS.ACCOUNT}`,
      list: `${ROOTS.ACCOUNT}/list`,
    },
    extract: {
      root: `${ROOTS.EXTRACT}`,
      list: `${ROOTS.EXTRACT}/generate`,
    },
    company: {
      root: `${ROOTS.COMPANY}`,
      list: `${ROOTS.COMPANY}/list`,
      new: `${ROOTS.COMPANY}/new`,
      edit: `${ROOTS.COMPANY}/edit`,
    }
  }
};
