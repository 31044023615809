import { createApi } from '@reduxjs/toolkit/query/react'
import {ICompany} from "../../types/company";
import {customBaseQuery, isValidToken} from "../../auth/context/jwt/utils";
import {ITenantPage} from "../../types/tenant";
import axios, {endpoints} from "../../utils/axios";

const path: string = 'companies'
export const companyService = createApi({
    reducerPath: 'companyService',
    tagTypes: ['company'],
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getCompanies: builder.query<ITenantPage, ITenantPage>({
            query: (page : ITenantPage) => ({
                url: `/${path}/list`,
                params: { ...page, content: [] }
            }),
            providesTags: ['company']
        }),
        getCompany: builder.mutation<ICompany, number>({
            query: (id: number) => ({
                url: `/${path}/findById/${id}`,
                method: 'GET'
            })
        }),
        saveCompany: builder.mutation<ICompany, ICompany>({
            query: (company: ICompany) => ({
                url: `/${path}/setup`,
                method: 'POST',
                body: company,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['company'],
        }),
        updateCompany: builder.mutation<ICompany, ICompany>({
            query: (data: ICompany) => ({
                url: `/${path}/update`,
                method: 'PUT',
                body: data,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['company']
        }),
    }),
})

export const {
    useGetCompaniesQuery,
    useSaveCompanyMutation,
    useGetCompanyMutation,
    useUpdateCompanyMutation,
} = companyService


export const activateCompany = async (id : number | undefined) => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken && isValidToken(accessToken) && id) {
        const res = await axios
            .get(`${endpoints.common.companies}/activate/${id}`,
                {headers: { Authorization: `Bearer ${accessToken}`}});

        return res.data;
    }
    return "";
}
export const deactivateCompany = async (id : number | undefined) => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken && isValidToken(accessToken) && id) {
        const res = await axios
            .get(`${endpoints.common.companies}/deactivate/${id}`,
                {headers: { Authorization: `Bearer ${accessToken}`}});

        return res.data;
    }
    return "";
}
